<template>
    <div>
        <div class="txt txt--sm txt--dark pb-12px pb-md-16px">Filter:</div>

        <v-row class="row--sm">
            <v-col cols="auto">
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <v-checkbox hide-details color="#333">
                    <template v-slot:label>
                        <div class="txt txt--sm">without overflow</div>
                    </template>
                </v-checkbox>
            </v-col>

            <v-col cols="auto">
                <v-checkbox hide-details color="#333">
                    <template v-slot:label>
                        <div class="txt txt--sm">Extra antibacterial surface with AntiBac</div>
                    </template>
                </v-checkbox>
            </v-col>
            <v-col cols="auto">
                <v-checkbox hide-details color="#333">
                    <template v-slot:label>
                        <div class="txt txt--sm">Easy to clean with Ceramic Plus</div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>

        <v-sheet color="grey lighten-5" class="filter-sheet mt-18px mt-24px mb-30px mb-md-56px pt-12px pb-24px">
            <div class="txt txt--sm mb-8px">Show results for:</div>
            <v-row class="row--xs">
                <v-col cols="auto">
                    <v-chip outlined color="grey darken-3" class="rounded-xs">
                        without overflow
                        <v-icon class="ml-8px">mdi-close</v-icon>
                    </v-chip>
                </v-col>
                <v-col cols="auto">
                    <v-chip outlined color="grey darken-3" class="rounded-xs">
                        without overflow
                        <v-icon class="ml-8px">mdi-close</v-icon>
                    </v-chip>
                </v-col>
                <v-col cols="auto">
                    <v-chip outlined color="grey darken-3" class="rounded-xs">
                        without overflow
                        <v-icon class="ml-8px">mdi-close</v-icon>
                    </v-chip>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn text x-small class="pa-0">
                        <span class="txt txt--xs txt--dark border-b">Delete All</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
    <!-- <div v-if="$vuetify.breakpoint.xlOnly">
        <v-divider class="grey darken-4" style="border-width: 2px 0 0" />
        <div class="px-md-16px py-20px py-md-30px">
            <filter-categories v-bind="{ brand }" :code="categoryCode" @search="$emit('search')" />
        </div>
        <v-divider />
        <div class="px-md-16px py-20px py-md-30px">
            <filter-prices />
        </div>
        <v-divider />
    </div>

    <div v-else>
        <filter-prices />
    </div> -->
</template>

<script>
import FilterPrices from "./filter-prices.vue";
import FilterCategories from "./filter-categories.vue";

export default {
    components: {
        FilterPrices,
        FilterCategories,
    },
    props: {
        brand: { type: Object },
        categoryCode: { type: String },
    },
};
</script>

<style lang="scss" scoped>
.filter-sheet {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-color: inherit;
        height: 100%;
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -1;
    }
}
</style>