var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark pb-12px pb-md-16px"
  }, [_vm._v("Filter:")]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "color": "#333"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("without overflow")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "color": "#333"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("Extra antibacterial surface with AntiBac")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "color": "#333"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('div', {
          staticClass: "txt txt--sm"
        }, [_vm._v("Easy to clean with Ceramic Plus")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-sheet', {
    staticClass: "filter-sheet mt-18px mt-24px mb-30px mb-md-56px pt-12px pb-24px",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm mb-8px"
  }, [_vm._v("Show results for:")]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded-xs",
    attrs: {
      "outlined": "",
      "color": "grey darken-3"
    }
  }, [_vm._v(" without overflow "), _c('v-icon', {
    staticClass: "ml-8px"
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded-xs",
    attrs: {
      "outlined": "",
      "color": "grey darken-3"
    }
  }, [_vm._v(" without overflow "), _c('v-icon', {
    staticClass: "ml-8px"
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "rounded-xs",
    attrs: {
      "outlined": "",
      "color": "grey darken-3"
    }
  }, [_vm._v(" without overflow "), _c('v-icon', {
    staticClass: "ml-8px"
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "pa-0",
    attrs: {
      "text": "",
      "x-small": ""
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark border-b"
  }, [_vm._v("Delete All")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }